import React from "react";
import { navigate } from "gatsby";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";

import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const NotFound = () => {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Headings.h3>
            404: Uh oh - this page doesn't exist or something went wrong :(
          </Headings.h3>
          <Button onClick={() => navigate("/")}>Go to home</Button>
        </div>
      </Section>
    </Layout>
  );
};

export default NotFound;

const Button = styled.button`
  width: 161px;
  height: 38px;
  margin-top: 30px;
  border: 1px solid ${(p) => p.theme.colors.accent};
  color: ${(p) => p.theme.colors.background};
  background: ${(p) => p.theme.colors.accent};
  font-weight: 600;
  border-radius: 35px;
  letter-spacing: 0.42px;
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
  &[disabled] {
    cursor: not-allowed;
  }
  ${(p) => mediaqueries.tablet`
    position: relative;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    border: none;
    border-radius: 0;
    border-top: 1px solid ${p.theme.colors.horizontalRule};
  `}
`;
